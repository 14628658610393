<template>
	<div class="container">
		<h2 class="label-header" style="margin-bottom: 15px;">Topic</h2>
		<div class="alert alert-danger alert-dismissable" v-if="showErrorPageAlert">
			{{ errorMessage }}
		</div>
		<div class="card">
			<div class="card-body" v-if="!isLoading">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th></th>
								<th style="width: 150px;">Course Code</th>
								<th>Course Title</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="courseList.length > 0">
								<tr v-for="(item, i) in courseList" :key="i">
									<th>{{ i + 1}}</th>
									<td style="width: 150px;">{{ item.course_code }}</td>
									<td>{{ item.course_title }}</td>
									<td>
										<button class="btn btn-sm btn-primary" style="margin-right: 10px;" @click.stop="openTopics(item)">View course topics</button>
									</td>
								</tr>
							</template>
							<template v-if="courseList.length === 0">
								<tr>
									<td style="text-align: center" colspan="4">No Course Added Yet</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-body loader" v-if="isLoading">
				<div class="spinner-border"></div>
				<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
			</div>
		</div>
		<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
						<button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<form @submit.prevent="onSubmit" novalidate>
							<div class="form-group mb-3">
								<label for="">Course Title</label>
								<select class="form-select" v-model="vv.course_id.$model" disabled>
									<option :value="initialVal">Please select the course for this topic</option>
									<option v-for="(item, i) in courseList" :key="i" :value="item.course_id">{{ `${item.course_code} (${item.course_title})` }}</option>
								</select>
								<span class="error">{{ vv?.course_id?.$errors[0]?.$message }}</span>
							</div>
							<div class="form-group" style="padding-bottom: 0px;">
								<table class="table">
									<thead>
										<tr>
											<th width="20px;"></th>
											<th>Topic title</th>
											<th style="width: 100px;"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, i) in vv.topic_title.$model" :key="i">
											<th width="20px;">{{ i + 1 }}</th>
											<td>
												<input type="text" v-model="item.topic_title" placeholder="Enter topic title" class="form-control">
											</td>
											<td style="width: 100px;">
												<button type="button" v-if="i > 0" class="btn btn-danger" @click="deleteRow(i, item)">Delete</button>
											</td>
										</tr>
									</tbody>
								</table>
								<span class="error">{{ (vv?.topic_title?.isAnyTopicEntered?.$invalid)? 'Please enter the topics':'' }}</span>
							</div>
							<div class="form-group">
								<button class="btn btn-primary" style="margin-right: 10px;"  :disabled="vv.$invalid || isProcessing">
									<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<span v-if="isProcessing" style="padding-left: 15px !important;">Saving.....</span>
									<span v-if="!isProcessing">Save</span>
								</button>
								<button type="button" class="btn btn-info text-white" style="margin-right: 10px;" @click="addMoreRow">Add more topics</button>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="modal.hide()">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Modal } from 'bootstrap'
import { projectService } from '@/services/project.service'

export default {
	name: 'Topics',
	setup() {
		const fform = reactive({
			course_id: null,
			topic_title: []
		})
		const rules = {
			course_id: { required },
			topic_title: {}
		}
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			initialVal: null,
			isProcessing: false,
			isLoading: true,
			courseList: [],
			modal: null,
			modalTitle: 'Course Topics',
			showErrorPageAlert: false,
			errorMessage: '',
			loadingMessage: 'Loading Courses ........',
		};
	},
	mounted() {
		this.modal = new Modal(this.$refs.exampleModal)
		this.loadContent()
	},
	methods: {
		loadContent () {
			this.isLoading = true
			const folder = this.$route.params.folder
			projectService.fetchProjectFile(`${folder}/course.json`)
				.then((response) => {
					this.isLoading = false
					this.courseList = response.data
				})
				.catch((error) => {
					console.log(error.message)
				})
		},
		async openTopics (item) {
			this.showErrorPageAlert = false
			this.fform.topic_title = []
			const folder = this.$route.params.folder
			console.log(item.course_id)
			projectService.fetchTopicFile(`${folder}/topic.json`, item.course_id)
				.then((response) => {
					if(parseInt(response.data.message_code) === 200){
						this.fform.topic_title = response.data.topic
						this.fform.course_id = item.course_id
						this.modal.show()
					}else{
						this.showErrorPageAlert = true
						this.errorMessage = response.data.message
					}
				})
				.catch((error) => {
					console.log(error.message)
					this.showErrorPageAlert = true
					this.errorMessage = 'Error Connecting To Server'
				})
		},
		addMoreRow () {
			this.fform.topic_title.push({
				topic_id: null,
				topic_title: ''
			})
		},
		deleteRow (index, item) {
			if(item.topic_id !== null){
				this.$swal({
					text: `Are you sure to delete this topic?`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, Delete',
					allowOutsideClick: false
				}).then((responses) => {
					if(responses.isConfirmed) {
						const data = {
							topic_id: item.topic_id,
							folder: this.$route.params.folder
						}
						projectService.deleteTopic(data)
							.then((response) => {
								if(parseInt(response.data.message_code) === 200){
									this.$swal({
										icon: "success",
										text: "Topic Deleted Successfully"
									})
									this.fform.topic_title.splice(index, 1)
								}else{
									this.$swal({
										icon: "error",
										text: response.data.message
									})
								}
							})
							.catch((error) => {
								console.log(error.message)
								this.$swal({
									icon: "error",
									text: "Error Connecting To The Server"
								})
							})
					}
				})
			}else{
				this.fform.topic_title.splice(index, 1)
			}
		},
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			if(this.fform.topic_title[0].topic_title.length === 0){
				this.$swal({
					icon: "error",
					text: "Enter atleast a topic before submitting"
				})
				return
			}
			const postData = {
				course_id: this.fform.course_id,
				topics: this.fform.topic_title,
				folder: this.$route.params.folder
			}
			projectService.createTopic(postData)
				.then((response) => {
					this.isProcessing = false
					if(parseInt(response.data.message_code) === 200){
						this.$swal({
							icon: "success",
							text: "Topic Saved Successfully"
						})
						// this.showErrorPageAlert = true
						// this.errorMessage = 'Topic Saved'
						this.modal.hide()
					}else{
						this.$swal({
							icon: "error",
							text: response.data.message
						})
					}
				})
				.catch((error) => {
					this.isProcessing = false
					console.log(error.message)
					this.$swal({
						icon: "error",
						text: "Error Connecting To The Server"
					})
				})
		}
	}
};
</script>

<style scoped>
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>